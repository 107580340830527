<script setup lang="ts">
import { arrayify, spellLevel, titleCase } from '../util'
import type { SpellPage } from '../content/SpellListing.vue'
import SpellTableCell from './SpellTableCell.vue'
import type { ParsedContent } from '@nuxt/content'

const areaIcons: Record<string, string> = {
  circle: 'fa6-regular:circle',
  sphere: 'fa6-solid:circle',
  cylinder: 'fa6-solid:database',
  line: 'fa6-solid:equals',
  cube: 'fa6-solid:cube',
  cone: 'fa6-solid:less-than',
  emanation: 'fa6-solid:circle',
}

const spellAreaIcon = (area: string | number) => areaIcons[area] || ''

const props = defineProps<{
  spell: SpellPage | ParsedContent
}>()

const saves = computed(() => arrayify(props.spell.saves))
const effects = computed(() => arrayify(props.spell.effects))

const rangeText = computed(() => {
  if (Number.isInteger(props.spell.range)) return `${props.spell.range} ft`
  return titleCase(props.spell.range)
})

const saveAliases: Record<string, string> = {
  str: 'STR save',
  dex: 'DEX save',
  con: 'CON save',
  int: 'INT save',
  wis: 'WIS save',
  cha: 'CHA save',
}

const saveAlias = (eff: string) => saveAliases[eff] || titleCase(eff)
</script>

<template>
  <div class="spell-table">
    <table class="nostripes">
      <tbody>
        <tr>
          <SpellTableCell title="Level">{{ spellLevel(spell.level) }}</SpellTableCell>
          <SpellTableCell title="Casting Time"
            >{{ titleCase(spell.castingTime) }}&nbsp;
            <Icon v-if="spell.reactionTo" name="fa6-solid:asterisk" class="marker" />
            <Icon v-else-if="spell.ritual" name="fa6-solid:registered" title="Ritual" class="marker" />
          </SpellTableCell>
          <SpellTableCell title="Range/Area">
            {{ rangeText }}
            <span v-for="(feet, area) in spell.area" :key="area">
              &nbsp;({{ feet }} ft <Icon :name="spellAreaIcon(area)" />)
            </span>
          </SpellTableCell>
          <SpellTableCell title="Components">
            {{ spell.components.join(', ').toUpperCase() }}
            <Icon v-if="spell.materialComponent" name="fa6-solid:asterisk" />
          </SpellTableCell>
        </tr>
        <tr>
          <SpellTableCell title="Duration">
            <Icon v-if="spell.concentration" name="fa6-solid:copyright" title="Concentration" class="marker" />
            {{ titleCase(spell.duration) }}
          </SpellTableCell>
          <SpellTableCell title="School">{{ titleCase(spell.school) }}</SpellTableCell>
          <SpellTableCell title="Attack/Save">
            {{
              saves
                .slice(0, 2)
                .map((save) => saveAlias(save || ''))
                .join(', ')
            }}
            {{ saves.length > 2 ? '...' : '' }}&nbsp;
          </SpellTableCell>
          <SpellTableCell title="Damage/Effect">
            {{
              effects
                .slice(0, 2)
                .map((eff) => titleCase(eff))
                .join(', ')
            }}
            {{ effects.length > 2 ? '...' : '' }}&nbsp;
          </SpellTableCell>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="postcss">
.spell-table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  border-bottom: 2px solid var(--primary);
  margin-bottom: 1em;

  > table {
    width: 100%;

    .icon {
      color: var(--text-highlight);
    }

    .marker {
      margin-right: 0.5em;
    }

    td {
      width: 25%;
      max-width: 25%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
