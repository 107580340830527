<template>
  <td class="cell">
    <div class="cell-title">{{ props.title }}</div>
    <div class="cell-value">
      <slot />
    </div>
  </td>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string
}>()
</script>

<style scoped lang="postcss">
.cell {
  padding: 0.5rem 1rem;

  .cell-title {
    font-weight: 500;
    color: var(--text-highlight);
  }

  .cell-value {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
